<template>
    <div>
      <h3 v-if="!valid" class="error-message"> {{ error_message }}</h3>
      <div v-show="valid" class="stream-video" >
      </div>
    </div>
  </template>

  <script>

  import {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VBTooltip,
  } from "bootstrap-vue";
  import router from '@/router';
  import axios from 'axios';
  import { Camera } from "@omnicloud/cameras-sdk";

  import { ref, onMounted } from "@vue/composition-api";

  export default {
    directives: {
      "b-tooltip": VBTooltip,
    },
    components: {
      BRow,
      BCol,
      BLink,
      BFormGroup,
      BFormInput,
      BInputGroupAppend,
      BInputGroup,
      BFormCheckbox,
      BCardText,
      BCardTitle,
      BImg,
      BForm,
      BButton,
      BAlert,
    },
    setup() {
    //   const alarm = router.currentRoute.params.alarm;
    //   const token = router.currentRoute.params.token;
      const valid = ref(false);
      const cameraSDK = ref(null);
      const error_message = ref('');

      const appLoading = document.getElementById('loading-bg');
      if (appLoading) {
        appLoading.style.display = 'block'
      }

      onMounted(() => {
        const stream_video = document.querySelector('.stream-video');

        if (!router.currentRoute.query.access_token) {
          error_message.value = 'Unauthorized';
          appLoading.style.display = 'none';
          return;
        }

        if (!router.currentRoute.query.id_camera) {
          error_message.value = 'Missing camera id';
          appLoading.style.display = 'none'
          return;
        }

        axios.get(`${process.env.VUE_APP_BASE_URL}/v1/proxy_camera/${router.currentRoute.query.id_camera}`,
        {
          params: {
            waiting: 1,
            attending: 1
          },
          headers: {
            Authorization: `Bearer ${router.currentRoute.query.access_token}`,
          }
        })
        .then(result => {
          let data = result.data.data;
          cameraSDK.value = new Camera(data.type, {
            cameraId: data.camera.id,
            token: router.currentRoute.query.access_token,
            htmlElement: `.stream-video`,
            style: "float",
            apiUrl: `${process.env.VUE_APP_BASE_URL}/v1`,
            debugMode: "info|error",
            mode: "default",
            capabilities: {
              closeBtn: false,
              dateRangeBtn: false,
              date: false,
              recordingBtn: false,
              timelineBtn: false,
              liveBtn: false,
              downloadBtn: false
            },
          });

          if (router.currentRoute.query.start && router.currentRoute.query.end) {
            console.log(router.currentRoute.query)
            cameraSDK.value.buildPlayer("historical", router.currentRoute.query.start, router.currentRoute.query.end);
          } else {
            cameraSDK.value.buildPlayer();
          }

          appLoading.style.display = 'none';
          stream_video.style.display = 'block';
        })
        .catch(err => {
          error_message.value = err.response.data.message;
          appLoading.style.display = 'none';
        });
      })

      return {
        valid,
        error_message,
      };
    },
  };
  </script>

  <style lang="scss">
  .stream-video {
    width: 100vw;
    height: 100vh;
  }

  .error-message {
    font-size: 5vw;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  </style>
